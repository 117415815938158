<template>
  <div class="game-content">
    <div>
       <iframe class="gameframe" ref="gameframe" :src="url" frameborder="0" @load="onLoad"/>
    </div>

    <van-popup v-model:show="popShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
        <iframe class="popframe" :src="popUrl" frameborder="0"/>
    </van-popup>

    <!-- <drag-ball :value="message" @click="menuShow=true"/> -->

    <van-popup v-model:show="menuShow" position="left"  :style="{ width: '70%', maxWidth: '440px', height: '100%' }" >
        <div class="nav_left">
            <van-sidebar v-model="activeKey" class="">
                <van-sidebar-item>
                  <template #title> 
                      <van-icon name="gift-o" class="tab-icon" />
                      <span>推薦</span>
                  </template>
                </van-sidebar-item>
                <van-sidebar-item  to="/">
                  <template #title> 
                      <van-icon name="gift-o" class="tab-icon"/>
                      <span>主頁</span>
                  </template>
                </van-sidebar-item>
            </van-sidebar>
        </div>

        <div class="nav_right">
          <ul class="game-list">
                <li>
                    <img src="https://play-lh.googleusercontent.com/DfQmsF_XIBNhSIQe3MMeG9HeIuybjEgu1nYW-lk3zJ78GCDiQo36GGlniJgsRTdt27sk=s180-rw" class="game-img" />
                    <div class="txt">
                        <p class="txt-title">
                          <span>放置三国</span>
                        </p>
                        <p class="txt-gray">放置三国A放置三国A放置三国A放置三国</p>
                    </div>
                </li>
            </ul>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, onMounted, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Toast, Dialog } from 'vant'
import { payMode } from "@/service/pay"
// import DragBall from '@/components/DragBall'
import { requestInfo } from "@/service/web";
import * as system from '@/utils/system'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()

        const state = reactive({
            gameId: 0,
            url:"",
            popUrl:"",
            popShow: false,
            menuShow: false,
        })
        const gameframe = ref(null)

        onMounted(async () => {
            init();
            window.addEventListener('loginSuccess', function () {  
                state.url = "";
                init()
            }, false);
        });

        const init = async() => {
            if (window.location.href.startsWith("https")) {
                window.location.href = window.location.href.replace("https", "http")
                return;
            }

            const { id } = route.params;
            state.gameId = parseInt(id);
            store.commit("setNeedLogin", true);
            if ( !store.getters.isLogin ) {
                return;
            }

            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', function () {
                history.pushState(null, null, document.URL);
                Dialog.alert({
                    message: "退出游戏",
                    confirmButtonColor: "#6e87ea"
                }).then(() => {
                    window.location.href = "https://" + window.location.host
                });
            });

            Toast.loading('加载中');
            let result = await requestInfo(state.gameId);

            state.url = result.url;
            window.addEventListener('message', handleMessage, false);

        }

        const handleMessage = (event) => {
            var result = event.data;
            switch(result.msg) {
                case "userCenter":
                    openUserCenter(result.params)
                break;
                case "payOrder":
                    pay(result.params)
                break;
                case "hzsdkPay":
                    if (result.success) {
                        // let sUserAgent = navigator.userAgent
                        // if (/Safari/.test(sUserAgent) && !/Chrome/.test(sUserAgent) && sUserAgent.toLowerCase().indexOf('qqbrowser')==-1) {
                            
                        // }
                        // window.top.location.href = result.pay_url;
                        window.open(result.pay_url)
                    }
                break;
                case "switchAccount":
                    state.popShow = false
                    store.dispatch('logout')
                break;
            }
        }

        const openUserCenter = (data) => {
            state.popUrl = "http://www.handzone.xyz/handzone/usercenter/index.html" + "?gameId=" + state.gameId +
                      "&gameName=" + "" +
                      "&userName=" + store.getters.username +
                      "&server=" + data.server +
                      "&roleId=" + data.roleId +
                      "&rolename=" + data.rolename +
                      "&vip=" + data.vip +
                      "&token=" + store.getters.token +
                      "&roleLevel=" + data.roleLevel +
                      "&userId=" + store.getters.userId +
                      "&channel=" + store.getters.channel +
                      "&lang=" + store.getters.lang +
                      "&isHtml5=1" +
                      "&time=" + Math.random();
            state.popUrl = encodeURI(state.popUrl);
            state.popShow = true
        }

        const pay = async (data) => {
            Toast.loading({
                message: '请求支付中',
                forbidClick: true
            });
            const result  = await payMode({
                "gameId": state.gameId,
                "level": data.roleLevel || 0,
                "channel": store.getters.channel
            });
            Toast.clear();
            state.popUrl = "http://www.handzone.xyz/handzone/kf/h5Index.html" + "?gameId=" + state.gameId +
                "&server=" + data.server +
                "&roleId=" + data.roleId +
                "&rolename=" + data.rolename +
                "&level=" + data.level +
                "&amount=" + data.amount +
                "&productId=" + data.productId +
                "&outOrderNo=" + data.outOrderNo +
                "&mode=" + result.mode +
                "&token=" + store.getters.token +
                "&channel=" + store.getters.channel + 
                "&discount=" + result.discount + 
                "&isHtml5=1" + 
                "&lang=" + store.getters.lang +
                "&currency=CNY" + 
                "&time=" + Math.random();
            state.popUrl = encodeURI(state.popUrl);
            state.popShow = true
        }

        const sendMessage = (msg, params) => {
            try {
              gameframe.value.contentWindow.postMessage({
                  msg: msg,
                  params: params
              }, '*')
            } catch (error) {1}
        }

        const onLoad = () => {
            sendMessage("initGame", {
                deviceId:"",
                username:store.getters.username,
                uid:store.getters.userId,
                token:store.getters.token,
                gameId: state.gameId,
                channel: store.getters.channel,
                os:store.getters.os,
                osVersion: system.GetOs(),
                model: system.GetCurrentBrowser(),
                ssid: "",
                lang: store.getters.lang,
            })
        }

        return {
          ...toRefs(state),
          gameframe,
          sendMessage,
          onLoad
        }
    },
    components: {
    //   DragBall
    }
}
</script>

<style lang="less" scoped>
.game-content {
    background-color: #000;
    height: calc(var(--vh, 1vh) * 100); 
}

.gameframe {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
}

.popframe {
    height: 100%;
    width: 100%;
}

@media screen and (min-width: 640px) {
  iframe {
    border-radius: 5px;
  }
}

.nav_left {
  background: #f5f5f5; 
height: calc(var(--vh, 1vh) * 100);
}

.nav_right {
  /*右侧盒子使用了绝对定位*/ 
  position: absolute; 
  top: 0; 
  right: 0; 
  flex: 1; 
  /*宽度75%，高度占满，并使用百分比布局*/ 
  width: 75%; 
  height: 100%; 
  box-sizing: border-box; 
  background: #f7f8fa; 
  padding-top: 10px;
}

.game-list {
      background: #fff;
      cursor: pointer;
      li {
          overflow: hidden;
          padding: 5px;
          border-bottom: 1px solid #f1f1f1;

          &:last-child {
              border-bottom: 0px;
          }
          .game-img {
              float: left;
              width: 50px;
              height: 50px;
              margin-right: 5px;
              border-radius: 10px;
          }
          .txt {
              text-align: left;
              float: left;
              width: 70%;
          }
          .txt-title {
              font-size: 16px;
              color: #333;
              margin: 0 0 5px;
              line-height: 20px;
              .tag {
                  line-height: 20px;
                  margin-left: 5px;
              }
          }
          .button-start {
              right: 10px;
              background: #6e87ea;
              color: #fff;
              font-size: 16px;
              max-width: 100px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              border-radius: 5px;
              padding: 5px 5px;
          }
          .txt-gray {
              color: #999;
              font-size: 13px;
              line-height: 13px;
          }
      }
  }
</style>